import React, { Component, Fragment, useState, useEffect } from "react";
import "react-dat-gui/build/react-dat-gui.css";

import ForceGraph3D from "react-force-graph/src/packages/react-force-graph-3d";
import ForceGraph2D from "react-force-graph/src/packages/react-force-graph-2d";
//import { ForceGraph2D, ForceGraph3D, ForceGraphVR } from 'react-force-graph';
import DatGui, { DatPresets, DatBoolean, DatColor, DatNumber, DatString } from "react-dat-gui";
import queryString from "query-string";
import PropTypes from "prop-types";

import "./App.css";
class App extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    data: {
      nodes: [],
      links: []
    },
    datadat: {
      group: "4e8d168e1b0b455c703f7227df4d1ee77ce9be88ea4c78b2a93bf96d8e36b717",
      numDimensions: 2,
      isAwesome: false
    }
  };
  _handleClick = node => {
    // Remove node on click
  };

  updateGraphData() {
    const { data, datadat } = this.state;
    // small d341b1f78b61c4fe72a74d2b9b97bcff454173ea81d4ac6df6639ae948fc55a0
    //small 9d0c3dfa25abd9bd81bd834b21f6ea35668c1ef6497d77c0ac41ee9db6aabab3
    // big 0fa0a673d50ce4b400f4281ac98171321ee629ab11f704908d1a0e99a88c0242
    fetch("https://wss.peer-stream.com:9001/redis/smemberspeers/group-peers-" + datadat.group)
      .then(response => response.json())
      .then(response =>
        this.setState(({ data: { nodes, links } }) => {
          let linksres = response.links;

          let testlink = [];

          for (let test of response.links) {
            const entry = JSON.parse(test);

            if (entry && entry.statistics.p2pPeerConnections) {
              for (let link of entry.statistics.p2pPeerConnections) {
                if (response.nodes.includes(link)) testlink.push({ source: entry.guid.split("-")[0], target: link.split("-")[0] });
              }
            }
          }
          return {
            data: {
              nodes: [...response.nodes.map(i => ({ id: i.split("-")[0], value: "1" }))],
              links: [...testlink]
            }
          };
        })
      )
      .catch();
  }
  componentDidMount() {
    //this.updateGraphData();
    setInterval(this.updateGraphData.bind(this), 1000);
    const values = queryString.parse(this.props.location.search);

    const testnetid = values.groupid || "4e8d168e1b0b455c703f7227df4d1ee77ce9be88ea4c78b2a93bf96d8e36b717";
    this.state.datadat.group = testnetid;
  }

  handleUpdate = datadat => {
    const { data } = this.state;

    data.group = datadat.group;
    if (datadat.isAwesome) datadat.numDimensions = 3;
    else datadat.numDimensions = 2;
    this.setState({ datadat });
  };
  render() {
    const { data, datadat } = this.state;
    const presetA = {
      group: "4e8d168e1b0b455c703f7227df4d1ee77ce9be88ea4c78b2a93bf96d8e36b717"
    };
    const values = queryString.parse(this.props.location.search);

    const testnetid = values.groupid || "4e8d168e1b0b455c703f7227df4d1ee77ce9be88ea4c78b2a93bf96d8e36b717";
    
    const presets = [
      { TestNet: { ...datadat, group: testnetid } },
      { GroupA: { ...datadat, ...presetA } },
      { GroupB: { ...datadat, group: "d341b1f78b61c4fe72a74d2b9b97bcff454173ea81d4ac6df6639ae948fc55a0" } }
    ];
    return (
      <div>
       <DatGui data={datadat} onUpdate={this.handleUpdate}>
          <DatPresets label="Presets" options={presets} onUpdate={this.handleUpdate} />
          <DatBoolean path="isAwesome" label="3D?" />
        </DatGui>
        <ForceGraph3D
          ref={el => {
            this.fg = el;
          }}
          enableNodeDrag={false}
          linkDirectionalArrowLength={0}
          forceEngine={"d3"}
          onNodeClick={this._handleClick}
          nodeLabel={"id"}
          numDimensions={datadat.numDimensions}
          cooldownTime={0}
          cooldownTicks={0}
          nodeOpacity={1}
          graphData={data}
        />
      </div>
    );
  }
}

export default App;
